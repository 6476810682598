import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import { primary } from 'styles/colors';
import { mobile, useQuery } from 'styles/breakpoints';
import {
  BenefitItem,
  Container,
  FlexWrapper,
  H1,
  Image,
  ParallaxCache,
  TextColor,
} from 'components';

const Benefits: React.FC = React.memo(() => {
  const { isMobile } = useQuery();

  return (
    <StyledContainer>
      <ParallaxCache />
      <HeadingWrapper>
        <FirstPillImage src={`pill`} width={isMobile ? '2.25rem' : '4.25rem'} />
        <SecondPillImage
          src={`pill`}
          width={isMobile ? '2.25rem' : '4.25rem'}
        />
        <ThirdPillImage
          src={`pill`}
          width={isMobile ? '2.625rem' : '4.875rem'}
        />
        <FourthPillImage src={`pill`} width={isMobile ? '3rem' : '5.5rem'} />
        <H1 textAlign="center" style={{ position: 'relative' }}>
          Over 74,212 cardiovascular systems improved with&nbsp;
          <TextColor color={primary} className="italic">
            Pulsio
          </TextColor>
        </H1>
      </HeadingWrapper>
      <FlexWrapper
        alignItems="center"
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
        padding={isMobile ? '4.125rem 0 0' : '6.625rem 0 0'}
      >
        <Parallax
          disabled={isMobile}
          y={isMobile ? [0, 0] : [-10, 30]}
          styleOuter={isMobile ? { marginBottom: '1.25rem' } : null}
        >
          <BenefitItem
            percentage="95"
            text="of customers noticed highly improved stamina due to better blood pressure"
          />
        </Parallax>
        <Parallax
          disabled={isMobile}
          y={isMobile ? [0, 0] : [30, -10]}
          styleOuter={isMobile ? { marginBottom: '1.25rem' } : null}
        >
          <BenefitItem
            percentage="83"
            text="of consumers reduced bad cholesterol levels"
          />
        </Parallax>
        <Parallax disabled={isMobile} y={isMobile ? [0, 0] : [60, -50]}>
          <BenefitItem
            percentage="91"
            text="of consumers lost unhealthy weight within a month after taking Pulsio"
          />
        </Parallax>
      </FlexWrapper>
    </StyledContainer>
  );
});

Benefits.displayName = 'Benefits';

export default Benefits;

const StyledContainer = styled(Container)`
  padding-top: 11.25rem;
  padding-bottom: 9.375rem;

  @media ${mobile} {
    padding-top: 5.875rem;
    padding-bottom: 3.75rem;
  }
`;
const HeadingWrapper = styled.div`
  position: relative;
  max-width: 50rem;
  width: 100%;
  margin: 0 auto;
`;

const FirstPillImage = styled(Image)`
  position: absolute;
  top: -5rem;
  left: 27%;
  transform: rotate(135deg) scale(0.5);
  @media ${mobile} {
    top: -3rem;
    left: 19%;
  }
`;
const SecondPillImage = styled(Image)`
  position: absolute;
  top: -2.5rem;
  right: 22%;
  transform: rotate(45deg);

  @media ${mobile} {
    top: -0.5rem;
    right: 12%;
  }
`;
const ThirdPillImage = styled(Image)`
  position: absolute;
  bottom: 1rem;
  left: 16%;
  transform: rotate(45deg) scale(0.9);

  @media ${mobile} {
    bottom: -1rem;
    left: 4%;
  }
`;
const FourthPillImage = styled(Image)`
  position: absolute;
  bottom: -4.125rem;
  right: 11%;
  transform: rotate(135deg) scale(1.05);

  @media ${mobile} {
    bottom: -3.25rem;
  }
`;
